/* Add to _sass/_base.scss or create a new file */
/* Light mode variables (default) */
:root {
    --primary-color: #4361ee;
    --secondary-color: #3a0ca3;
    --accent-color: #4cc9f0;
    --text-color: #2b2d42;
    --text-light: #8d99ae;
    --background-color: #ffffff;
    --background-alt: #f8f9fa;
    --border-color: #e9ecef;
    --success-color: #2ecc71;
    --warning-color: #f39c12;
    --error-color: #e74c3c;

    /* Other existing variables */
    --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-heading: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-mono: 'SF Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', monospace;
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 2rem;
    --spacing-xl: 3rem;
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 20px rgba(0, 0, 0, 0.1);
    --transition-fast: 0.2s ease;
    --transition-normal: 0.3s ease;
    --transition-slow: 0.5s ease;
}

/* Dark mode variables - activated by system preference */
@media (prefers-color-scheme: dark) {
    :root {
        --primary-color: #6C8EFF;
        /* Lighter blue for better visibility in dark mode */
        --secondary-color: #8A63EC;
        /* Lighter purple */
        --accent-color: #64D6FF;
        /* Lighter cyan */
        --text-color: #e9ecef;
        /* Light text */
        --text-light: #adb5bd;
        /* Medium-light text */
        --background-color: #121212;
        /* Dark background */
        --background-alt: #1e1e1e;
        /* Slightly lighter dark background */
        --border-color: #2a2a2a;
        /* Dark borders */

        /* Update shadow with appropriate opacity for dark mode */
        --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.5);
        --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
        --shadow-lg: 0 10px 20px rgba(0, 0, 0, 0.3);

        /* Keep success, warning, and error colors the same for recognition */
    }
}

/* Base element styling */
body {
    font-family: var(--font-primary);
    color: var(--text-color);
    line-height: 1.6;
    background-color: var(--background-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-heading);
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: var(--spacing-md);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

p {
    margin-bottom: var(--spacing-md);
}

a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color var(--transition-fast);
}

a:hover {
    color: var(--secondary-color);
    text-decoration: none;
    border-bottom: none;
}

/* Modern button styles */
.button,
.learn-more {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    background-color: var(--primary-color);
    color: white !important;
    border-radius: var(--border-radius-md);
    font-weight: 600;
    text-align: center;
    transition: all var(--transition-fast);
    border: none;
    box-shadow: var(--shadow-sm);
}

.button:hover,
.learn-more:hover {
    background-color: var(--secondary-color);
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
    border-bottom: none;
}

.button-outline {
    background-color: transparent;
    color: var(--primary-color) !important;
    border: 2px solid var(--primary-color);
}

.button-outline:hover {
    background-color: var(--primary-color);
    color: white !important;
}

/* Modern card styling */
.card {
    background-color: var(--background-color);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    padding: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
    transition: transform var(--transition-normal);
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
}

/* Container and layout */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-lg);
}

.wrapper {
    padding: var(--spacing-lg) 0;
}

/* Grid system */
.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--spacing-lg);
}

.col-4 {
    grid-column: span 4;
}

.col-6 {
    grid-column: span 6;
}

.col-8 {
    grid-column: span 8;
}

.col-12 {
    grid-column: span 12;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .col-4,
    .col-6,
    .col-8 {
        grid-column: span 12;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.75rem;
    }
}

/* Updated navigation */
.site-navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: var(--spacing-md) 0;
}

.site-navigation ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.site-navigation li {
    margin-right: var(--spacing-md);
    padding: 0;
    background: none;
}

.site-navigation a {
    font-weight: 500;
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius-sm);
    transition: all var(--transition-fast);
}

.site-navigation a:hover {
    background-color: var(--background-alt);
    color: var(--primary-color);
}

/* Product cards */
.product-box {
    margin-bottom: var(--spacing-xl);
}

.product-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.product-box li {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-xl);

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
    }
}

.product-box .img-box {
    flex: 0 0 200px;
    margin-bottom: var(--spacing-md);

    @media (min-width: 768px) {
        margin-bottom: 0;
        margin-right: var(--spacing-lg);
    }
}

.product-box .img-box img {
    width: 100%;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-sm);
}

.product-box .text-box {
    flex: 1;
}

.product-box .text-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: var(--spacing-sm);
    color: var(--primary-color);
}

.product-box .a-box {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-lg);
}

.product-box .learn-more {
    margin-right: var(--spacing-md);
}

/* Footer styling */
.site-footer {
    background-color: var(--background-alt);
    padding: var(--spacing-xl) 0;
    margin-top: var(--spacing-xl);
    border-top: 1px solid var(--border-color);
}

.footer-heading {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: var(--spacing-sm);
}