/* Base styles for our modernized design system */

/* Reset and normalization */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--background-color);
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-display);
  font-weight: var(--font-weight-semibold);
  line-height: 1.2;
  margin-bottom: var(--spacing-md);
  color: var(--text-color);
}

h1 {
  font-size: var(--font-size-4xl);
}

h2 {
  font-size: var(--font-size-3xl);
}

h3 {
  font-size: var(--font-size-2xl);
}

h4 {
  font-size: var(--font-size-xl);
}

h5 {
  font-size: var(--font-size-lg);
}

h6 {
  font-size: var(--font-size-base);
}

p {
  margin-bottom: var(--spacing-md);
  color: var(--text-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-fast);
  
  &:hover {
    color: var(--primary-dark);
    text-decoration: none;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color);
  }
}

/* Lists */
ul, ol {
  list-style-position: inside;
  margin-bottom: var(--spacing-md);
  padding-left: var(--spacing-md);
}

li {
  margin-bottom: var(--spacing-xs);
}

/* Code blocks */
pre, code {
  font-family: var(--font-mono);
  font-size: var(--font-size-sm);
  background-color: var(--code-background);
  border-radius: var(--border-radius-sm);
}

code {
  padding: 0.2em 0.4em;
}

pre {
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
  
  code {
    background-color: transparent;
    padding: 0;
  }
}

/* Horizontal rule */
hr {
  border: 0;
  height: 1px;
  background-color: var(--divider-color);
  margin: var(--spacing-xl) 0;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* Buttons */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--primary-color);
  color: white;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-pill);
  border: none;
  cursor: pointer;
  transition: all var(--transition-fast);
  text-decoration: none;
  line-height: 1.5;
  
  &:hover, &:focus {
    background-color: var(--primary-dark);
    color: white;
    text-decoration: none;
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--background-color), 0 0 0 4px var(--primary-color);
  }
  
  &:disabled {
    background-color: var(--text-tertiary);
    cursor: not-allowed;
    transform: none;
  }
  
  /* Variant: Secondary button */
  &.button-secondary {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    
    &:hover, &:focus {
      background-color: rgba(0, 113, 227, 0.05);
      color: var(--primary-color);
    }
    
    &:disabled {
      color: var(--text-tertiary);
      border-color: var(--text-tertiary);
      background-color: transparent;
    }
  }
  
  /* Variant: Text button */
  &.button-text {
    background-color: transparent;
    color: var(--primary-color);
    padding: var(--spacing-xs) var(--spacing-sm);
    
    &:hover, &:focus {
      background-color: transparent;
      color: var(--primary-dark);
      text-decoration: underline;
    }
  }
  
  /* Size variants */
  &.button-sm {
    font-size: var(--font-size-sm);
    padding: var(--spacing-xs) var(--spacing-md);
  }
  
  &.button-lg {
    font-size: var(--font-size-lg);
    padding: var(--spacing-md) var(--spacing-xl);
  }
  
  /* Icon button */
  &.button-icon {
    padding: var(--spacing-xs);
    border-radius: 50%;
    
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

/* Container */
.container {
  width: 100%;
  max-width: var(--container-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

/* Grid system */
.grid {
  display: grid;
  gap: var(--spacing-lg);
}

/* Card */
.card {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-lg);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
  }
}

/* Helpers */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Responsive utilities */
.hide-on-mobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.hide-on-desktop {
  @media (min-width: 769px) {
    display: none !important;
  }
}

/* Focus styles for keyboard navigation */
:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Dark mode image adjustments */
@media (prefers-color-scheme: dark) {
  img:not([src*=".svg"]) {
    filter: brightness(0.9);
  }
}
