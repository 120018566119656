/* Components styling for our modernized design system */

/* Product grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: var(--spacing-lg);
}

.product-card {
  position: relative;
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
}

.product-icon {
  padding: var(--spacing-lg);
  display: flex;
  justify-content: center;
  background-color: var(--background-secondary);
  
  img {
    width: 120px;
    height: 120px;
    border-radius: 22%;
    box-shadow: var(--shadow-sm);
  }
}

.product-info {
  padding: var(--spacing-lg);
}

.product-info h3 {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-sm);
}

.product-info p {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-base);
}

.product-features {
  list-style: none;
  padding: 0;
  margin: var(--spacing-md) 0;
}

.product-features li {
  position: relative;
  padding-left: var(--spacing-lg);
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  
  &::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--success-color);
    font-weight: bold;
  }
}

.product-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-lg);
  flex-wrap: wrap;
  gap: var(--spacing-sm);
}

/* Blog cards */
.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
}

.post-card {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  padding: var(--spacing-lg);
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
}

.post-meta {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  flex-wrap: wrap;
}

.post-category {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--font-size-xs);
  border-radius: var(--border-radius-pill);
  text-transform: uppercase;
}

.post-title {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-sm);
  
  a {
    color: var(--text-color);
    
    &:hover {
      color: var(--primary-color);
    }
  }
}

.post-excerpt {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
  flex: 1;
}

/* Testimonial */
.testimonial {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  position: relative;
  
  &::before {
    content: '"';
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 60px;
    color: var(--primary-color);
    opacity: 0.2;
    font-family: Georgia, serif;
    line-height: 1;
  }
}

.testimonial-content {
  font-style: italic;
  padding-left: var(--spacing-md);
  position: relative;
  z-index: 1;
}

.testimonial-author {
  margin-top: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.author-name {
  font-weight: var(--font-weight-medium);
}

.author-title {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

/* Tags */
.tag {
  display: inline-block;
  padding: 4px 10px;
  background-color: var(--background-secondary);
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-right: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  transition: all var(--transition-fast);
  
  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-2xl);
}

.pagination-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  background-color: var(--background-secondary);
  color: var(--text-color);
  transition: all var(--transition-fast);
  
  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  &.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  &.prev, &.next {
    width: auto;
    padding: 0 var(--spacing-md);
  }
}

/* Alerts */
.alert {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  border-left: 4px solid var(--primary-color);
  background-color: rgba(var(--primary-color-rgb, 0, 113, 227), 0.05);
  display: flex;
  align-items: flex-start;
  
  &.alert-info {
    background-color: rgba(var(--primary-color-rgb, 0, 113, 227), 0.05);
    border-left-color: var(--primary-color);
  }
  
  &.alert-success {
    background-color: rgba(var(--success-color-rgb, 52, 199, 89), 0.05);
    border-left-color: var(--success-color);
  }
  
  &.alert-warning {
    background-color: rgba(var(--warning-color-rgb, 255, 149, 0), 0.05);
    border-left-color: var(--warning-color);
  }
  
  &.alert-error {
    background-color: rgba(var(--error-color-rgb, 255, 59, 48), 0.05);
    border-left-color: var(--error-color);
  }
  
  .alert-icon {
    margin-right: var(--spacing-sm);
    font-size: 1.2rem;
    color: var(--primary-color);
    
    .alert-success & {
      color: var(--success-color);
    }
    
    .alert-warning & {
      color: var(--warning-color);
    }
    
    .alert-error & {
      color: var(--error-color);
    }
  }
  
  .alert-content {
    flex: 1;
  }
  
  .alert-title {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-xs);
  }
  
  .alert-text {
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
  }
}

/* Notes */
.note {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  background-color: var(--background-secondary);
  margin: var(--spacing-md) 0;
  
  &.warning-note {
    background-color: rgba(var(--warning-color-rgb, 255, 149, 0), 0.05);
    border-left: 4px solid var(--warning-color);
  }
}

/* Post navigation */
.post-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xl);
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.prev-post, .next-post {
  padding: var(--spacing-md);
  background-color: var(--card-background);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--divider-color);
  transition: all var(--transition-fast);
  
  &:hover {
    background-color: var(--background-secondary);
  }
}

.next-post {
  text-align: right;
}

.nav-label {
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
  
  .prev-post & {
    justify-content: flex-start;
    
    i {
      margin-right: var(--spacing-xs);
    }
  }
  
  .next-post & {
    justify-content: flex-end;
    
    i {
      margin-left: var(--spacing-xs);
    }
  }
}

.nav-title {
  font-weight: var(--font-weight-medium);
}

/* Advertisement containers */
.ad-container {
  position: relative;
  margin: var(--spacing-lg) auto;
  padding: var(--spacing-md);
  background-color: var(--background-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  text-align: center;
  max-width: 800px;
}

.ad-label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 6px;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8);
  border-bottom-left-radius: 3px;
  font-weight: 400;
}

.ad-content {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .ad-label {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.6);
  }
}

/* View all button */
.view-all {
  text-align: center;
  margin-top: var(--spacing-xl);
}
