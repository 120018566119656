/* Dark mode adjustments for images and specific elements */
@media (prefers-color-scheme: dark) {

    /* Adjust brightness for regular images */
    img:not([src*=".svg"]) {
        filter: brightness(0.9);
    }

    /* Exception for logos and app icons */
    .site-logo img,
    .product-icon img,
    .app-store-badge img {
        filter: none;
    }

    /* Add invert filter for App Store badge which has a black logo */
    .app-store-badge img {
        filter: brightness(0.9);
    }

    /* Invert code blocks for better readability */
    pre,
    code {
        background-color: #2a2a2a;
    }

    /* Adjust blockquote styling */
    blockquote {
        border-left-color: var(--primary-color);
        background-color: rgba(255, 255, 255, 0.05);
    }

    /* Card hover effects adjustment */
    .card:hover,
    .product-card:hover {
        box-shadow: var(--shadow-lg);
        background-color: var(--background-alt);
    }
}