/* Layout components for our modernized design system */

/* Header */
.site-header {
  position: sticky;
  top: 0;
  z-index: var(--z-sticky);
  background-color: rgba(var(--background-color-rgb, 255, 255, 255), 0.95);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid var(--divider-color);
  padding: var(--spacing-sm) 0;
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}

.site-logo {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: var(--font-weight-semibold);
  transition: opacity var(--transition-fast);
  
  &:hover {
    opacity: 0.8;
  }
  
  img {
    height: 32px;
    width: auto;
    margin-right: var(--spacing-xs);
  }
}

.site-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-color);
}

/* Navigation */
.site-navigation {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin: 0;
    padding: 0;
  }
  
  a {
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-medium);
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius-pill);
    transition: all var(--transition-fast);
    
    i {
      margin-right: var(--spacing-xs);
      font-size: 0.9em;
      opacity: 0.8;
    }
    
    &:hover {
      color: var(--primary-color);
      background-color: rgba(var(--primary-color-rgb, 0, 113, 227), 0.05);
    }
    
    &.active {
      color: var(--primary-color);
    }
  }
}

/* Mobile menu toggle */
.menu-toggle {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: var(--spacing-xs);
  height: 44px;
  width: 44px;
  
  span {
    display: block;
    width: 22px;
    height: 2px;
    background-color: var(--text-color);
    border-radius: 1px;
    margin: 4px auto;
    transition: all var(--transition-fast);
  }
  
  &.active {
    span:nth-child(1) {
      transform: translateY(6px) rotate(45deg);
    }
    
    span:nth-child(2) {
      opacity: 0;
    }
    
    span:nth-child(3) {
      transform: translateY(-6px) rotate(-45deg);
    }
  }
}

/* Mobile navigation */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  
  .site-navigation {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--background-color);
    border-bottom: 1px solid var(--divider-color);
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--transition-normal);
    
    &.active {
      max-height: 300px;
    }
    
    ul {
      flex-direction: column;
      padding: var(--spacing-md);
    }
    
    li {
      width: 100%;
    }
    
    a {
      padding: var(--spacing-md);
      width: 100%;
      border-radius: var(--border-radius-md);
    }
  }
}

/* Footer */
.site-footer {
  background-color: var(--background-secondary);
  padding: var(--spacing-2xl) 0;
  margin-top: var(--spacing-3xl);
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--spacing-lg);
}

.footer-info {
  grid-column: span 4;
  
  @media (max-width: 768px) {
    grid-column: span 12;
    text-align: center;
  }
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-md);
  
  @media (max-width: 768px) {
    justify-content: center;
  }
  
  img {
    height: 32px;
    width: auto;
    margin-right: var(--spacing-xs);
  }
}

.footer-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-color);
  margin-bottom: var(--spacing-xs);
}

.footer-description {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.footer-links {
  grid-column: span 8;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: var(--spacing-lg);
  
  @media (max-width: 768px) {
    grid-column: span 12;
  }
}

.footer-section {
  h4 {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-md);
    color: var(--text-color);
    
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  
  li {
    margin-bottom: var(--spacing-sm);
  }
  
  a {
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
    transition: color var(--transition-fast);
    
    &:hover {
      color: var(--primary-color);
    }
  }
}

.social-links {
  display: flex;
  gap: var(--spacing-md);
  
  @media (max-width: 768px) {
    justify-content: center;
  }
  
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: all var(--transition-fast);
    
    &:hover {
      background-color: var(--primary-color);
      color: white;
      transform: translateY(-2px);
    }
    
    i {
      font-size: 1.2rem;
    }
  }
}

.footer-bottom {
  grid-column: 1 / -1;
  border-top: 1px solid var(--divider-color);
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-lg);
}

.copyright {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  text-align: center;
}

/* Page layout */
.page-content {
  min-height: calc(100vh - 300px);
}

/* Hero section */
.hero {
  background-color: var(--background-secondary);
  padding: var(--spacing-3xl) 0;
  margin-bottom: var(--spacing-2xl);
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.hero-title {
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-md);
  
  @media (max-width: 768px) {
    font-size: var(--font-size-3xl);
  }
}

.hero-subtitle {
  font-size: var(--font-size-xl);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  
  @media (max-width: 768px) {
    font-size: var(--font-size-lg);
  }
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

/* Section headers */
.section-header {
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.section-title {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-sm);
  
  @media (max-width: 768px) {
    font-size: var(--font-size-2xl);
  }
}

.section-description {
  color: var(--text-secondary);
  max-width: 700px;
  margin: 0 auto;
}

/* Product layout */
.product-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: var(--spacing-lg);
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  margin-bottom: var(--spacing-xl);
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.product-media {
  padding: var(--spacing-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-secondary);
  
  img {
    max-width: 100%;
    max-height: 200px;
    border-radius: var(--border-radius-md);
  }
}

.product-content {
  padding: var(--spacing-lg);
}

.product-title {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
}

.product-description {
  margin-bottom: var(--spacing-lg);
  color: var(--text-secondary);
}

.app-store-badge {
  display: inline-block;
  
  img {
    height: 40px;
    transition: opacity var(--transition-fast);
    
    &:hover {
      opacity: 0.8;
    }
  }
}

/* Blog posts */
.blog-post {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  overflow: hidden;
  margin-bottom: var(--spacing-lg);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: var(--shadow-lg);
  }
}

.post-header {
  padding: var(--spacing-lg);
  border-bottom: 1px solid var(--divider-color);
}

.post-meta {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  
  time {
    font-size: var(--font-size-sm);
    color: var(--text-tertiary);
  }
  
  .post-category {
    display: inline-block;
    padding: 2px 8px;
    background-color: var(--primary-color);
    color: white;
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    border-radius: var(--border-radius-pill);
    text-transform: uppercase;
  }
}

.post-title {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-sm);
  
  a {
    color: var(--text-color);
    
    &:hover {
      color: var(--primary-color);
    }
  }
}

.post-excerpt {
  color: var(--text-secondary);
}

.post-content {
  padding: var(--spacing-lg);
}

.read-more {
  display: inline-flex;
  align-items: center;
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
  margin-top: var(--spacing-md);
  
  i {
    margin-left: var(--spacing-xs);
    transition: transform var(--transition-fast);
  }
  
  &:hover {
    i {
      transform: translateX(3px);
    }
  }
}

/* Page layout specific styles */
.page-header {
  background-color: var(--background-secondary);
  padding: var(--spacing-2xl) 0;
  margin-bottom: var(--spacing-2xl);
  text-align: center;
}

.page-header h1 {
  font-size: var(--font-size-4xl);
  margin-bottom: var(--spacing-sm);
  
  @media (max-width: 768px) {
    font-size: var(--font-size-3xl);
  }
}

.page-header p {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

/* Features section */
.features-section {
  padding: var(--spacing-2xl) 0;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
}

.feature-item {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: var(--shadow-lg);
  }
  
  h3 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-sm);
    color: var(--text-color);
  }
  
  p {
    color: var(--text-secondary);
  }
}

/* Product info layout */
.product-info {
  display: grid;
  grid-template-columns: minmax(200px, 300px) 1fr;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-2xl);
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.product-icon {
  @media (max-width: 768px) {
    margin: 0 auto var(--spacing-lg);
  }
  
  img {
    width: 100%;
    max-width: 180px;
    border-radius: 22%;
    box-shadow: var(--shadow-md);
  }
}

/* Support section */
.support-section {
  padding: var(--spacing-xl) 0;
}

.support-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.support-card {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  text-align: center;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: var(--shadow-lg);
  }
}

.card-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: var(--spacing-md);
}

/* FAQ section */
.faq-section {
  margin-top: var(--spacing-2xl);
}

.faq-item {
  border-bottom: 1px solid var(--divider-color);
  margin-bottom: var(--spacing-md);
}

.faq-question {
  padding: var(--spacing-md) 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h3 {
    font-size: var(--font-size-lg);
    margin: 0;
    font-weight: var(--font-weight-medium);
  }
}

.toggle-icon {
  font-size: 1rem;
  color: var(--primary-color);
  transition: transform var(--transition-fast);
}

.faq-item.active .toggle-icon {
  transform: rotate(45deg);
}

.faq-answer {
  padding: 0 0 var(--spacing-md);
  display: none;
  color: var(--text-secondary);
}

.faq-item.active .faq-answer {
  display: block;
}

/* Privacy policy */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
}

.policy-content {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xl);
  box-shadow: var(--shadow-md);
}

/* Release notes */
.release-notes {
  max-width: 800px;
  margin: 0 auto;
}

.release-item {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  box-shadow: var(--shadow-md);
}

.release-date {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-sm);
}

.build-number {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  font-weight: normal;
}

/* Blog layout */
.blog-layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--spacing-xl);
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}

.blog-sidebar {
  @media (max-width: 992px) {
    display: none;
  }
}

.sidebar-widget {
  background-color: var(--card-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  box-shadow: var(--shadow-md);
}

.widget-title {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--divider-color);
}

/* Contact CTA */
.contact-cta {
  background-color: var(--primary-color);
  color: white;
  padding: var(--spacing-2xl) 0;
  margin-top: var(--spacing-2xl);
}

.cta-content {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.cta-content h2 {
  color: white;
  margin-bottom: var(--spacing-md);
}

.cta-content p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: var(--spacing-lg);
}

.cta-content .button {
  background-color: white;
  color: var(--primary-color);
  
  &:hover {
    background-color: var(--background-secondary);
    color: var(--primary-color);
  }
}
