@charset "utf-8";

/* Import variables and base styles */
@use "variables";
@use "base";
@use "layout";
@use "components";
@use "dark-mode";

/* Custom color/background variables calculation for rgba values */
:root {
  --background-color-rgb: 255, 255, 255;
  --text-color-rgb: 29, 29, 31;
  --primary-color-rgb: 0, 113, 227;
  --secondary-color-rgb: 29, 29, 31;
  --success-color-rgb: 52, 199, 89;
  --warning-color-rgb: 255, 149, 0;
  --error-color-rgb: 255, 59, 48;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color-rgb: 29, 29, 31;
    --text-color-rgb: 245, 245, 247;
    --primary-color-rgb: 10, 132, 255;
  }
}

/* Additional styling for store badges */
.store-badges {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.app-store-badge,
.google-play-badge {
  display: inline-block;
  transition: opacity var(--transition-fast);
  
  &:hover {
    opacity: 0.8;
  }
  
  img {
    height: 40px;
    width: auto;
  }
}

/* Add styles for scrolled header */
.site-header.scrolled {
  box-shadow: var(--shadow-md);
}

/* Values section */
.values-section {
  padding: var(--spacing-3xl) 0;
  background-color: var(--background-secondary);
}

.values-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

/* Screenshots section */
.screenshots-section {
  margin-top: var(--spacing-2xl);
}

.screenshot-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.screenshot-item {
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

/* Testimonials section */
.testimonials-section {
  margin-top: var(--spacing-2xl);
}

.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

/* Platform badges styles */
.platform-badges {
  display: flex;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
}

.platform-badge {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
  background-color: var(--background-secondary);
  color: var(--text-secondary);
  
  i {
    margin-right: 4px;
    font-size: 10px;
  }
}

/* Product card action buttons - fix for layout issues */
.product-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-lg);
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  
  .button {
    flex-shrink: 0;
    min-width: 100px; /* Minimum width to prevent squishing */
  }
  
  .app-store-badge,
  .google-play-badge {
    flex-shrink: 0;
  }
  
  .store-badges {
    flex-shrink: 0;
  }
}

/* View All Section - fix for spacing */
.view-all {
  text-align: center;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-2xl); /* Increased spacing at the bottom */
}

/* Blog post styling enhancements */
.blog-post-single {
  max-width: 800px;
  margin: 0 auto;
}

.post-content {
  h2 {
    font-size: var(--font-size-2xl);
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-md);
  }
  
  h3 {
    font-size: var(--font-size-xl);
    margin-top: var(--spacing-lg);
    margin-bottom: var(--spacing-md);
  }
  
  img {
    border-radius: var(--border-radius-md);
    margin: var(--spacing-lg) 0;
  }
  
  blockquote {
    border-left: 4px solid var(--primary-color);
    padding: var(--spacing-md) var(--spacing-lg);
    margin: var(--spacing-lg) 0;
    background-color: var(--background-secondary);
    border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    
    p:last-child {
      margin-bottom: 0;
    }
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: var(--spacing-lg) 0;
    
    th, td {
      padding: var(--spacing-sm) var(--spacing-md);
      border: 1px solid var(--border-color);
    }
    
    th {
      background-color: var(--background-secondary);
      font-weight: var(--font-weight-semibold);
      text-align: left;
    }
    
    tr:nth-child(even) {
      background-color: var(--background-secondary);
    }
  }
}

/* Add animation for hero buttons */
.hero-buttons .button {
  transition: transform var(--transition-fast), box-shadow var(--transition-fast), background-color var(--transition-fast);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
  }
}

/* Apply smooth transitions to links */
a {
  transition: color var(--transition-fast);
}

/* Override old typography styles */
.post {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
}

.download {
  padding: var(--spacing-md);
}

/* Product features with icons */
.feature-with-icon {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
  
  .feature-icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: var(--spacing-md);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--primary-color-rgb), 0.1);
    color: var(--primary-color);
    border-radius: 50%;
    
    i {
      font-size: 1.5rem;
    }
  }
  
  .feature-content {
    flex: 1;
  }
  
  h3 {
    margin-bottom: var(--spacing-xs);
  }
  
  p {
    color: var(--text-secondary);
  }
}

/* Product page navigation styles */
.product-navigation {
  background-color: var(--background-secondary);
  padding: var(--spacing-sm) 0;
  margin-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--divider-color);
}

.product-nav {
  display: flex;
  justify-content: center;
}

.product-nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: var(--border-radius-pill);
  background-color: var(--background-color);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}

.product-nav-links li {
  margin: 0;
}

.product-nav-links a {
  display: block;
  padding: var(--spacing-sm) var(--spacing-lg);
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-fast);
  position: relative;
}

.product-nav-links a:hover {
  background-color: rgba(var(--primary-color-rgb), 0.05);
  color: var(--primary-color);
}

.product-nav-links a.active {
  background-color: var(--primary-color);
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-nav-links {
    flex-direction: column;
    width: 100%;
    border-radius: var(--border-radius-md);
  }
  
  .product-nav-links li {
    width: 100%;
  }
  
  .product-nav-links a {
    text-align: center;
    padding: var(--spacing-md) var(--spacing-lg);
  }
}

/* Platform availability badges in product cards */
.platform-badges {
  display: flex;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  
  .platform-badge {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: var(--border-radius-pill);
    font-size: var(--font-size-xs);
    background-color: var(--background-secondary);
    color: var(--text-secondary);
    
    i {
      margin-right: 4px;
      font-size: 10px;
    }
  }
}

/* Common page styles for unified look and feel */
.page-header {
  background-color: var(--primary-color);
  color: white;
  padding: var(--spacing-2xl) 0;
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

.page-header h1 {
  font-size: var(--font-size-4xl);
  margin-bottom: var(--spacing-sm);
  color: white;
}

.page-header p {
  font-size: var(--font-size-lg);
  color: rgba(255, 255, 255, 0.9);
  max-width: 700px;
  margin: 0 auto;
}

/* Unified card styling */
.card-content {
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  margin-bottom: var(--spacing-lg);
}

/* Release notes styling */
.releases-list {
  margin-top: var(--spacing-lg);
}

.release-item {
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  box-shadow: var(--shadow-sm);
}

.release-header {
  margin-bottom: var(--spacing-md);
}

.release-date {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: var(--border-radius-sm);
  font-size: 0.875rem;
  margin-bottom: var(--spacing-sm);
}

.build-number {
  font-size: 1rem;
  color: var(--text-secondary);
  font-weight: normal;
}

/* Privacy policy unified styling */
.privacy-policy h1,
.release-notes h1,
.product-detail h1 {
  font-size: 2rem;
  margin-bottom: var(--spacing-lg);
  color: var(--primary-color);
}

.policy-content {
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-sm);
}

.policy-content h3 {
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  color: var(--text-color);
  font-size: 1.25rem;
}

.policy-content h4 {
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
  color: var(--text-color);
  font-size: 1.1rem;
}
