/* Modern design system inspired by professional developer sites */
:root {
  /* Color system */
  --primary-color: #0071e3;       /* Primary blue */
  --primary-dark: #0051a2;        /* Darker blue for hover states */
  --secondary-color: #1d1d1f;     /* Near black for text */
  --accent-color: #fa5252;        /* Accent red for important elements */
  --success-color: #34c759;       /* Success green */
  --warning-color: #ff9500;       /* Warning orange */
  --error-color: #ff3b30;         /* Error red */
  
  /* Neutral palette */
  --text-color: #1d1d1f;          /* Primary text color */
  --text-secondary: #6e6e73;      /* Secondary text color */
  --text-tertiary: #86868b;       /* Tertiary text color */
  --background-color: #ffffff;    /* Main background color */
  --background-secondary: #f5f5f7; /* Secondary background color */
  --border-color: #d2d2d7;        /* Border color */
  --divider-color: #e5e5e5;       /* Divider/separator color */
  
  /* Component colors */
  --card-background: #ffffff;     /* Card background */
  --tooltip-background: #1d1d1f;  /* Tooltip background */
  --code-background: #f6f8fa;     /* Code block background */
  
  /* Typography */
  --font-primary: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'SF Pro Display', 'Helvetica Neue', Arial, sans-serif;
  --font-display: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Helvetica Neue', Arial, sans-serif;
  --font-mono: 'SF Mono', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  
  /* Font weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  
  /* Font sizes */
  --font-size-xs: 0.75rem;        /* 12px */
  --font-size-sm: 0.875rem;       /* 14px */
  --font-size-base: 1rem;         /* 16px */
  --font-size-lg: 1.125rem;       /* 18px */
  --font-size-xl: 1.25rem;        /* 20px */
  --font-size-2xl: 1.5rem;        /* 24px */
  --font-size-3xl: 1.875rem;      /* 30px */
  --font-size-4xl: 2.25rem;       /* 36px */
  --font-size-5xl: 3rem;          /* 48px */
  
  /* Spacing */
  --spacing-xxs: 0.25rem;         /* 4px */
  --spacing-xs: 0.5rem;           /* 8px */
  --spacing-sm: 0.75rem;          /* 12px */
  --spacing-md: 1rem;             /* 16px */
  --spacing-lg: 1.5rem;           /* 24px */
  --spacing-xl: 2rem;             /* 32px */
  --spacing-2xl: 2.5rem;          /* 40px */
  --spacing-3xl: 3rem;            /* 48px */
  --spacing-4xl: 4rem;            /* 64px */
  
  /* Borders */
  --border-radius-sm: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-pill: 9999px;
  
  /* Shadows */
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.05);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.05), 0 10px 10px -5px rgba(0, 0, 0, 0.05);
  
  /* Transitions */
  --transition-fast: 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-normal: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-slow: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Container */
  --container-max-width: 1280px;
  --container-padding: 1.5rem;
  
  /* Z-indices */
  --z-dropdown: 10;
  --z-sticky: 20;
  --z-fixed: 30;
  --z-modal: 40;
  --z-popover: 50;
  --z-tooltip: 60;
}

/* Dark mode overrides */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #0a84ff;     /* Adjusted blue for dark mode */
    --primary-dark: #409cff;      /* Lighter for hover in dark mode */
    --text-color: #f5f5f7;        /* Light text for dark mode */
    --text-secondary: #a1a1a6;    /* Light secondary text for dark mode */
    --text-tertiary: #86868b;     /* Light tertiary text for dark mode */
    --background-color: #1d1d1f;  /* Dark background */
    --background-secondary: #2d2d2d; /* Slightly lighter dark background */
    --border-color: #424245;      /* Darker border color */
    --divider-color: #38383a;     /* Darker divider/separator */
    --card-background: #2d2d2d;   /* Darker card background */
    --code-background: #2d2d2d;   /* Darker code block background */
    
    /* Adjusted shadows for dark mode */
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.2);
    --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.2);
  }
}

/* Additional styles for values section and other new components */

/* Values section */
.values-section {
  padding: var(--spacing-3xl) 0;
  background-color: var(--background-secondary);
}

.values-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

/* Screenshots section */
.screenshots-section {
  margin-top: var(--spacing-2xl);
}

.screenshot-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.screenshot-item {
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

/* Testimonials section */
.testimonials-section {
  margin-top: var(--spacing-2xl);
}

.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

/* Platform badges styles */
.platform-badges {
  display: flex;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
}

.platform-badge {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
  background-color: var(--background-secondary);
  color: var(--text-secondary);
  
  i {
    margin-right: 4px;
    font-size: 10px;
  }
}
