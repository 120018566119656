/* Dark mode specific adjustments */
@media (prefers-color-scheme: dark) {
  /*
   * Most dark mode colors are already handled by CSS variables,
   * but some specific adjustments need to be made for certain elements
   */
  
  /* Better contrast for code blocks */
  pre, code {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  /* Adjust image brightness */
  img:not([src*=".svg"]) {
    filter: brightness(0.85);
  }
  
  /* Exception for logos and app icons */
  .site-logo img,
  .product-icon img,
  .app-store-badge img {
    filter: none;
  }
  
  /* Subtle adjustment for App Store badge */
  .app-store-badge img {
    filter: brightness(0.9);
  }
  
  /* Enhance borders in dark mode for better visibility */
  .card,
  .product-card,
  .feature-item,
  .blog-post,
  .sidebar-widget {
    border: 1px solid var(--border-color);
  }
  
  /* Header and footer transparency adjustments */
  .site-header {
    background-color: rgba(29, 29, 31, 0.8); /* Match --background-color in dark mode */
  }
  
  /* Feature background */
  .feature-item:hover {
    background-color: var(--background-secondary);
  }
  
  /* Testimonial visibility */
  .testimonial::before {
    opacity: 0.1;
  }
  
  /* Alert colors need increased opacity in dark mode */
  .alert {
    &.alert-info {
      background-color: rgba(var(--primary-color-rgb, 10, 132, 255), 0.1);
    }
    
    &.alert-success {
      background-color: rgba(var(--success-color-rgb, 52, 199, 89), 0.1);
    }
    
    &.alert-warning {
      background-color: rgba(var(--warning-color-rgb, 255, 149, 0), 0.1);
    }
    
    &.alert-error {
      background-color: rgba(var(--error-color-rgb, 255, 59, 48), 0.1);
    }
  }
  
  /* Buttons need subtle borders in dark mode */
  .button.button-secondary {
    border-color: var(--primary-color);
  }
  
  /* Stronger focus styles for dark mode */
  :focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 3px;
  }
  
  /* Post navigation stronger borders */
  .prev-post, .next-post {
    border-color: var(--border-color);
  }
}
