.top-nav ul{
	overflow:hidden;
	font-size:16px;

}
.top-nav ul a:hover{
	border:none;

}
.site-navigation a:hover{
	border-bottom:1px solid #303030;
}

.top-nav li.li-1{
	background-image:url('../images/icon12.png');
}
.top-nav li.li-2{
	background-image:url('../images/icon13.png');
}
.top-nav li.li-3{
	background-image:url('../images/icon14.png');
}
.top-nav li.li-4{
	background-image:url('../images/icon15.png');
}
.top-nav li.li-5{
	background-image:url('../images/icon16.png');
}
.top-nav li{
	float:left;
	padding:0 10px 0 30px;
	list-style-type:none;
	background-position:left center;
	background-size:38px 22px;
	background-repeat:no-repeat;
}
.left-right{
	overflow:hidden;
}
.f-left{
	float:left;
}
.f-right{
	float:right;
}
.logo-box{
	padding-top:72px;

}
.logo-img{
	width:72px;
	padding-bottom:20px;
	margin:0 auto;
	
}
.logo-box h3{
	color:#02060b;
	font-size:16px;
	text-align:center;
}
.logo-box p{
	color:#666;
	font-weight:lighter;
	font-size:30px;
	text-align:center;
}
.logo-box ul{
	width:150px;
	margin:0 auto;
	font-weight:lighter;
	font-size:14px;
	color:#666;
}
.href-box{
	padding-top:40px;
	width:329px;
	margin:0 auto;
	overflow:hidden;
}
.learn-more{
	float:left;
	border:1px solid #9b9b9b;
	border-radius:5px;
	color:#9b9b9b;
	width:150px;
	height:38px;
	background-color:#fafafa;
}
.learn-more a{
	
	line-height:30px;
	padding:5px 0 5px 50px;
	background:url('../images/learn-more.png') no-repeat 10px center /20%;
	display:block;
	font-size:18px;
}

.href-box a:hover{
	border:none;
	color:#aaa;
}
.href-box a:visited{
	border:none;
	color:#aaa;
}
.href-box .go{
	float:right;
}
.product-box h2{
	padding-top:40px;
	font-size:40px;
	text-align:center;
	line-height:40px;
	padding-bottom:50px;
}
.product-box li{
	overflow:hidden;
	padding-bottom:60px;
}
.product-box li .img-box{
	float:left;
	width:200px;
}
.product-box li .text-box{
	width:430px;
	float:right;
}
.product-box li .text-box .text-title{
	font-size:24px;
	font-weight:lighter;
	padding-bottom:10px;
}
.product-box li .text-box .text-content{
	color:#666;
	line-height:24px;
	letter-spacing:1px;
}
.svg{
	margin-left:20px;
	float:left;
}
.product{
	border-bottom:1px solid #ddd;
	padding-bottom:50px;
}
.none-border{
	border:0 none;
}
.width-292{
	width:292px;
}
.list-box{
	padding:40px 0;
}
.list-1 .left-box{
	width:55%;
}
.list-1 .right-box{
	width:45%;
}
.head-box .icon-box {
	width:70px;
}
.list-1 .head-box .icon-box{
	padding-right:20px;
	
}
.list-2 .head-box .icon-box{
	padding-left:20px;
	
}
.head-box .icon-box img{
	width:100%;
}
.head-box .title-box {
	font-size:30px;
	padding-bottom:10px;
	line-height:70px;
	font-weight:lighter;
}
.head-box .title-box span{
	font-weight:bold;
}
.list-1 .text-box{
	padding-left:90px;
}
.list-1 .text-box p{
	font-size:19px;
	padding:5px;
}
.list-1 .right-box img{
	width:80%;
	padding:40px;
}
.list-2 p{
	text-align:right;
	font-size:19px;
	padding:20px 0;
}
.list-2 .bottom-box{
	padding-top:30px;
}
.list-2 ul{
	overflow:hidden;
}
.list-2 li{
	list-style-type:none;
	text-align:center;
	padding:0 25px;
	width:80px;
	float:left;
}
.list-2 li img{
	width:100%;
}
.list-1 .bottom-box{
	padding-top:30px;
	text-align:center;
}
.list-1 .bottom-box img{
	width:60%;
}
.list-2 .img-box li{
	width:150px;
}
.list-3 .img-box{
	width:30%;
}
.list-3 h3{
	padding-top:20px;
	font-size:30px;
	font-weight:bold;
}
.list-3 .text-box{
	width:60%;
	padding-left:50px;
}
.feature-box .title-box{
	padding-top:30px;
	padding-bottom:50px;
	text-align:center;
}
.feature-box .title-box h2{
	font-size:40px;
}
.feature-box .title-box h2 span{
	font-weight:bold;
}
.feature-box .title-box p{
	font-size:18px;
	color:#9b9b9b;
}
.feature-list ul{
	overflow:hidden;
}
.feature-list li{
	width:40%;
	padding:0 30px;
	list-style:none;
	float:left;
}
.feature-list li i{
	display:block;
	width:25px;
	height:25px;
	float:left;
}
.feature-list li i.icon-green{
	background:url('../images/icon10.png') no-repeat center center /100%;

}
.feature-list li .con-title{
	overflow:hidden;
}
.feature-list li h4{
	font-size:18px;
	padding-left:10px;
	float:left;
}
.feature-list li p{
	padding-left:35px;
	color:#9b9b9b;
}
.feature-list li .con-title .tag{
	margin-left:10px;
	color:#fff;
	background-color:#666;
	border:1px solid #666;
	border-radius:5px;
	font-size:14px;
	padding:2px 4px;
}
.essential-box{
	padding:20px;
}
.essential-box .title-box{
	text-align:center;
}
.essential-box .title-box img{
	width:120px;
}
.essential-box .title-box h3{
	font-size:30px;
	font-weight:lighter;
}
.essential-box .list-box h4{
	font-size:20px;
	color:#4a4a4a;
}
.essential-box .list-box li{
	color:#666;
	line-height:30px;
	font-size:16px;
}
.release-box .title-box{
	padding-top:30px;
	padding-bottom:50px;
	text-align:center;
}
.release-box .title-box h2{
	font-size:40px;
}
.release-li{
	padding-bottom:30px;
	list-style:none;

}
.release-li .data-box{
	background-color:#50c019;
	color:#fff;
	border:1px solid #50c019;
	border-radius:5px;
	padding:5px 6px;
	font-size:18px;
}
.release-li h4{
	font-size:30px;
	padding-top:20px;
}
.release-li h4 span{
	color:#666;
	font-size:14px;
	padding-left:10px;
}
.release-li p{
	font-size:19px;
}
.release-li li{
	padding:5px 0;
}